import { BareFlexCol } from '@/components/common/commonStyles'
import Spinner from '@/components/common/Spinner'
import ItemHoverCard from '@/components/specific/item-hover-card'
import { useGetUserQuery } from '@/redux/features/api'
import React, { useCallback } from 'react'
import { DatedItem, DayOfWeekNum } from 'src/types'
import DayGrid from '../daygrid'
import { CalendarItem, LayoutUnit } from '../types'
import { getDaygridLayoutInfo } from '../utils'
import useCalendarItems from './useCalendarItems'
import { useUpdatePlannerItemMutation } from '@/redux/features/api'
import { usePutPlannerItem } from '@/hooks/main/category/usePutPlannerItem'
import useItemContextMenu from '@/hooks/main/category/useItemContextMenu'
import useGlobalForm from '@/hooks/useGlobalForm'

const Wrapper = ({ item: calItem, children, edit }: { item: CalendarItem, children: JSX.Element, edit?: () => void }) => {
    const item = calItem as DatedItem
    return (
        <ItemHoverCard item={item} edit={edit}>
            <BareFlexCol>
                {children}
            </BareFlexCol>
        </ItemHoverCard>
    )
}

const PlandaDaygrid = React.memo(({ date, layoutUnit = 'month', layoutVal = 1, setIsDragging }: {
    date: Date, layoutUnit?: LayoutUnit, layoutVal?: number,
    setIsDragging?: (isDragging: boolean) => void
}) => {
    const { start, end } = getDaygridLayoutInfo(layoutUnit, layoutVal, date, 1)
    const { data: user } = useGetUserQuery()
    const { putItem } = usePutPlannerItem()
    const [updateItem] = useUpdatePlannerItemMutation()
    const { calendarFormat } = useCalendarItems(start.getTime(), end.getTime())
    const { createItemContextMenu } = useItemContextMenu()

    const { openEditItemForm } = useGlobalForm({ type: 'event' })

    // const handleOpenForm = (defaultValues?: NewItemInfo) => {
    //     setDefaultFormValues({ ...defaultValues })
    //     setOpenForm(true)
    // }

    const edit = useCallback((id: string, updates: any) => updateItem({ id, updates: { set: updates } }), [updateItem])

    if (!user) return <Spinner />

    return (
        <DayGrid
            activeDay={date}
            layoutUnit={layoutUnit}
            layoutVal={layoutVal}
            weekStartsOn={(user.weekStartsOn % 7) as DayOfWeekNum}
            data={calendarFormat || []}
            handleDoubleClickDate={openEditItemForm}
            handleDateChange={edit}
            handleAddItem={putItem}
            // `/api/date?start=${start.getTime()}&end=${end.getTime()}&convertTemplateRecurs=false`
            contextMenu={createItemContextMenu}
            Wrapper={Wrapper}
            setIsDragging={setIsDragging}
        // contextMenu={(item) => createItemContex. .tMenu(item as Item, handleOpenForm, mutate)} // NOTE: difference between Item and Calendar item is name not requried in TemplateRecur
        />
    )
})
PlandaDaygrid.displayName = 'PlandaDaygrid'
export default PlandaDaygrid
