import { Item } from '@/types'
import React from 'react'

const Links = ({ item }: { item: Item }) => {
    if (!item.links) return null
    return (
        <ul className="link">
            {item.links.map((x, i) => (
                <li key={'link' + i}>
                    <a href={x.url}>{x.text}</a>
                </li>
            ))}
        </ul>
    )
}

export default Links
