import { format, formatDistanceToNow, isToday, isWithinInterval } from 'date-fns'
import { formatDateRange } from '@planda/utils'
import React from 'react'
import { styled } from 'styled-system/jsx'
import { Group } from './styles'
import { Event as EventType, isAllDayEvent } from 'src/types'
import { Unimportant } from 'src/components/common/commonStyles'
import ItemHoverCardLayout from './components/ItemHoverCardLayout'

const DATE_FORMAT = 'MMM. d, y'

const Flex = styled('div', {
    base: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
    },
})

const Event = ({ item, edit }: { item: EventType; edit?: () => void }) => {
    return (
        <ItemHoverCardLayout item={item} edit={edit}>
            {isAllDayEvent(item) ? <AllDay start={item.dateStart} /> : <DateInterval start={item.dateStart} end={item.dateEnd} />}
        </ItemHoverCardLayout>
    )
}

const AllDay = ({ start }: { start: number }) => {
    return (
        <Group>
            <label>{format(start, DATE_FORMAT)}</label>
            <label> All day </label>
            {<Unimportant>{isToday(start) ? 'Occuring now!' : formatDistanceToNow(start, { addSuffix: true })}</Unimportant>}
        </Group>
    )
}

const DateInterval = ({ start, end }: { start: number; end: number }) => {
    const interval = { start, end }
    return (
        <Group>
            <Flex>
                <label>{formatDateRange(interval)}</label>
                {/* <label>{formatTime(start) + ' - ' + formatTime(end)}</label> */}
                {/* <Unimportant>&nbsp;({formatDuration(intervalToDuration(interval), { format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'] })})</Unimportant> */}
            </Flex>
            <Unimportant>{isWithinInterval(Date.now(), interval) ? 'Occuring now!' : formatDistanceToNow(start, { addSuffix: true })}</Unimportant>
        </Group>
    )
}

export default Event
