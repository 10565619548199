import { Dialogues } from '../types'
import { useRouter } from 'next/navigation'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useGetRuleOfThreeQuery, useUpdateRuleOfThreeMutation } from '@/redux/features/api'
import { addDays, startOfDay } from 'date-fns'
import { formatDay } from '@planda/utils'
import { useEffect } from 'react'
import { setTemporaryState } from '@/redux/features/temporaryStateSlice'
import { setListPageView } from '@/redux/features/displayPreferencesSlice'

/**
 * Rule of Three Dialogue
 * RULE_OF_THREE_SETTINGS: { setAt: number (hr) }, setAt input is 'morning' | 'night before'
 * db: RULE_OF_THREE#2024-09-01, properties: tasks: string[], updatedAt: number, createdAt: number, popUpCount: number, snoozeTo?: number
 * pop-up condition: RULE_OF_THREE#<today's date> not set and if snoozeTo is set, Date.now() > snoozeTo
 * start -> navigates to /list, and turns SELECTING_RULE_OF_THREE in tempSlice to true, which turns selection mode on
 * later -> sets snoozeTo to 1 hr from now
 * not today -> sets snoozeTo to 1 day from now
 */
// const invalidRange = [13, 19]
const invalidRange = [14, 17]
export const useRuleOfThree = (setReady: (x: string, active?: boolean) => void) => {
    const router = useRouter()
    const selected = useAppSelector((state) => state.temporaryState.taskSelectionMode.selected)
    const isEnabled = useAppSelector((state) => state.featureAccess.featureAccessMap['rule-of-three'])
    const dispatch = useAppDispatch()
    const [update] = useUpdateRuleOfThreeMutation()
    const currentHour = new Date().getHours()
    const isSettingToday = currentHour < (invalidRange[0] + invalidRange[1]) / 2
    const dateToUse = isSettingToday ? new Date() : addDays(new Date(), 1)
    const dayString = formatDay(dateToUse)
    const { data, isLoading } = useGetRuleOfThreeQuery(dayString)
    const config = {
        defaultIsActive: false,
    }

    useEffect(() => {
        const triggerCondition = () => {
            if (!isEnabled || isLoading) return null
            if (data?.tasks && data.tasks.length === 3) return null
            if (data && data.snoozeTo && Date.now() < data.snoozeTo) return null
            const currentHour = new Date().getHours()

            if (currentHour >= invalidRange[0] && currentHour <= invalidRange[1]) {
                return false
            }
            return true
        }
        const res = triggerCondition()
        if (typeof res !== 'boolean') return
        setReady('rule-of-three', res)
    }, [data, dispatch, isEnabled, isLoading])

    const dialogues: Dialogues = {
        '0': {
            text: 'Pick your rule of three?' + (isSettingToday ? '' : ' (for tomorrow)'),
            responses: [
                {
                    next: 'start',
                    reply: 'Start now',
                    onClick: () => {
                        router.push('/list')
                        dispatch(setListPageView('list'))
                        dispatch(
                            setTemporaryState({
                                taskSelectionMode: {
                                    on: true,
                                    selected: [],
                                    maxSelect: 3,
                                },
                            })
                        )
                    },
                },
                {
                    reply: 'Later',
                    next: 'waiting',
                },
                {
                    reply: 'Not today',
                    next: 'end',
                    onClick: () => {
                        update({ day: dayString, updates: { set: { snoozeTo: startOfDay(addDays(Date.now(), 1)).getTime() } } })
                    },
                },
            ],
        },
        start: {
            text: `Which 3 tasks will you focus on ${isSettingToday ? 'today' : 'tomorrow'}?`,
            responses: [
                {
                    reply: 'Submit',
                    onClick: () => {
                        update({ day: dayString, updates: { set: { tasks: selected } } })
                        dispatch(setTemporaryState({ taskSelectionMode: { on: false, selected: [] } }))
                    },
                },
                {
                    reply: 'Cancel',
                },
            ],
        },
        waiting: {
            text: 'Sounds good. Tap me when you are ready.',
            next: '0',
            onClick: (setIsActive) => {
                setIsActive(false)
                // update({ day: dayString, updates: { set: { snoozeTo: Date.now() + MS_PER_HOUR } } })
            },
        },
    }

    return {
        dialogues,
        config,
    }
}
