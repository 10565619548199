import { styled } from 'styled-system/jsx'


export const Group = styled('div', {
    base: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "0.1rem",
        position: "relative",
        fontSize: '$3'
    }
});

export const IconBar = styled('div', {
    base: {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        position: "absolute",
        right: 0,
        top: 0,
        boxSizing: "border-box"
    }
});
