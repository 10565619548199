import React from 'react'
import { styled } from 'styled-system/jsx'
import { CalendarItem, CalItemWrapperType, isCalendarEvent, isCalendarTask } from '../../types'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { ContextMenuUnit, UnimportantInlineCategory } from '@/components/common'
import ContextMenu from '@/components/common/ContextMenu'
import { cloneDeep } from 'lodash'
import { formatTime } from '@planda/utils'
import { isAllDay } from '../../utils'
import { categoryColorDynamic } from '@/utils/categories'

const Item = ({ item, rootItem, isActive = false, className, style = {}, Wrapper, calendarId, contextMenu }:
    {
        item: CalendarItem, rootItem: CalendarItem, style?: Object, className?: string, isActive?: boolean,
        Wrapper?: CalItemWrapperType, calendarId: string,
        contextMenu?: (item: any, calendarItem: CalendarItem) => ContextMenuUnit[]
    }) => {
    const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
        id: item.id,
        data: {
            scope: calendarId,
            type: item.dateEnd ? 'event' : 'task'
            // name: item.name, type: 'taskEvent', isNew: true, duration: MS_PER_HALF_HOUR, color, category: task.category
        },
    });

    // useTraceUpdate({ item, isActive, style, Wrapper, calendarId })

    const startTime = (isCalendarEvent(item) && isAllDay(item)) ? "All-day" : formatTime(item.dateStart)
    // const allDay = isCalendarEvent(item) && isAllDay(item)
    const suffix = (item.categoryName ? `${item.categoryName} - ` : '') + startTime

    const contextMenuUnits = contextMenu && contextMenu(rootItem, cloneDeep(item))

    // @ts-expect-error
    const edit = contextMenuUnits?.find(x => typeof x === 'object' && x.label === 'Edit' && 'onSelect' in x)?.onSelect
    // {
    //     label: 'Edit',
    //     rightSlot: <Pencil1Icon />,
    //     onSelect: () => handleEdit(item), // TODO should open up a form, or switch tabs if inForm=true
    // },

    const isTask = isCalendarTask(item)
    const isCompleted = isTask && !!item.completed

    return (
        <ContextMenu title={item.name} units={contextMenuUnits} >
            <ItemStyle
                // @ts-expect-error
                priority={item.priority}
                className={className} completed={isCompleted} isDragging={isDragging} isActive={isActive} style={{ transform: CSS.Translate.toString(transform), ...style, ...categoryColorDynamic(item.colorNum) }}
                key={item.id} ref={setNodeRef} {...attributes} {...listeners}
            >
                {
                    Wrapper ? (
                        <Wrapper item={rootItem} edit={edit} >
                            <Inner>{item.name}<UnimportantInlineCategory>{suffix}</UnimportantInlineCategory></Inner>
                        </Wrapper>
                    ) :
                        <Inner>{item.name}<UnimportantInlineCategory>{suffix}</UnimportantInlineCategory></Inner>
                }
            </ItemStyle>
        </ContextMenu>
    )
}

export default Item

const Inner = styled('div', {
    variants: {},
    base: {
        size: "100%",
        lineHeight: '0.9em',
        fontSize: '0.9em',
        letterSpacing: '0.05em',
    }
})

export const ItemStyle = styled('div', {
    base: {
        borderRadius: '0.15rem',
        marginInline: '0.2rem',
        fontSize: '$1',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '0.2rem',
        pointerEvents: 'auto',
        zIndex: 10,
        animation: `fadeIn 200ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,

        boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
        transform: 'scale(1)',

        '&:hover': {
            cursor: 'grab',
        },

    },
    variants: {
        completed: {
            true: {
                opacity: 0.5,
                '&::before': {
                    position: 'absolute',
                    content: '""',
                    left: 5,
                    top: '50%',
                    right: 5,
                    borderTop: '2px solid',
                    borderColor: '$gray8',
                },
            }
        },
        isDragging: {
            true: {}
        },
        type: {
            task: {
                backgroundColor: 'LightCyan'
            },
            event: {
                color: '$eventtxt',
                backgroundColor: '$eventbg',
            },
            taskEvent: {
                backgroundColor: '$taskbg'
            },
        },
        isActive: {
            true: {
                animation: `fadeIn 200ms cubic-bezier(0.16, 1, 0.3, 1) forwards, pulse 2s 3`,
            }
        },
        priority: {
            2: {
                border: "2px solid {colors.$warning9}",
                boxShadow: '$boxShadow',
            }
        }
    }
})
