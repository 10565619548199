import React, { useCallback, useState } from 'react'
import { useRuleOfThree } from './useRuleOfThree'

export const useChooseDialogue = () => {
    const [isActive, setIsActive] = useState(false)
    const [readyQueue, setReadyQueue] = useState<string[]>([])

    const setReady = useCallback(
        (key: string, isActive = true) => {
            setReadyQueue((p) => [...p, key])
            if (isActive) setIsActive(true)
        },
        [setReadyQueue]
    )

    const onEnd = useCallback(() => {
        setReadyQueue((p) => p.slice(1))
    }, [])

    const { dialogues: ruleOfThreeDialogues } = useRuleOfThree(setReady)

    return {
        dialogues: readyQueue.length > 0 ? ruleOfThreeDialogues : null,
        onEnd,
        isActive,
        setIsActive,
    }
}
