import React, { ReactNode } from 'react'
import { css } from 'styled-system/css'
import { Group, IconBar } from '../styles'
import Edit from './Edit'
import { categoryColorDynamic } from '@/utils/categories'
import useCategoryNum from '@/hooks/useCategoryNum'
import { Item } from '@/types'
import { styled } from 'styled-system/jsx'
import Links from './Links'

const Flex = styled('div', {
    base: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
    },
})

const ItemHoverCardLayout = ({ item, edit, children }: { item: Item; edit?: () => void; children: ReactNode }) => {
    const { getCategoryNum } = useCategoryNum()
    const { color } = categoryColorDynamic(getCategoryNum(item.category) ?? item.type) || {}

    return (
        <Flex>
            <Group>
                <IconBar>
                    <Edit edit={edit} />
                </IconBar>
                <h3 className={css({ textAlign: 'center', color: 'inherit' })}>{item.name}</h3>
                <div className={css({ width: '100%', opacity: 0.3, height: 1, marginBottom: '$1' })} style={{ background: color }} />
                {/* {item.createdAt && <Unimportant>Created on {format(item.createdAt, DATE_FORMAT)}</Unimportant>} */}
                {children}
                {item.notes && <label>{item.notes}</label>}
                <Links item={item} />
            </Group>
        </Flex>
    )
}

export default ItemHoverCardLayout
