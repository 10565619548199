'use client'
// import { Toolbar } from "@/components/specific/toolbar/Toolbar";
import { css } from 'styled-system/css';
import DayPanel from '@/components/specific/toolbar/panels/DayPanel'
import { Flex, Spinner } from '@planda/design-system'
import { Cross1Icon } from '@radix-ui/react-icons'
import { AnimatePresence, motion } from 'framer-motion'
import Link from 'next/link'
import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { styled } from 'styled-system/jsx'
import { useGlobalPanda } from '@/components/specific/toolbar/components/useShowPanda'
import { useHotkeys } from 'react-hotkeys-hook';
import DayGridPanel from '@/components/specific/toolbar/panels/DayGridPanel'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { endViewMode, setViewMode } from '@/redux/features/view-mode/viewModeSlice'
import { useGetFeatureAccessQuery, useGetGeneralThemeCustomizationsQuery, useGetShareLinkSettingsQuery } from '@/redux/features/api';
import { skipToken } from '@reduxjs/toolkit/query'
import { useSession } from 'next-auth/react'
import { useRouter, useSearchParams } from 'next/navigation'
// import { OpenGlobalFormContext, useItemForm } from 'src/context/useGlobalForm'
import dynamic from 'next/dynamic';
import { openItemForm, setOpenItemForm } from '@/redux/features/temporaryStateSlice';
import { setCustomCategoryColorsGlobally } from '@/utils/categories';
import { ThemeCustomization } from '@/types/theme';
import GeneralPanda from '@/features/easter-eggs/choose/GeneralPanda';
import Woodpecker from '@/components/common/loaders/Woodpecker';

const ItemForm = dynamic(() => import('src/features/forms/item'))
const LazyLoadHooks = dynamic(() => import('./LazyLoad'))
const Lottie = dynamic(() => import('react-lottie-player'), {
  loading: () => <Woodpecker />,
  ssr: false
})
// Client Components:
const Toolbar = dynamic(() => import("@/components/specific/toolbar/Toolbar").then(m => m.Toolbar), {
  loading: () => <div className={css({ width: 41, height: '100%' })}></div>
})

const animation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
  transition: {
    duration: 1,
    ease: "easeInOut",
  },
}

export default function AuthLayout({ children, themeCustomizations: initialThemeCustomizations }: {
  children: React.ReactNode,
  themeCustomizations?: ThemeCustomization
}) {
  const viewMode = useAppSelector((state) => state.viewMode);
  const openForm = useAppSelector((state) => state.temporaryState.openForm);
  const defaultValues = useAppSelector((state) => state.temporaryState.defaultFormItems);
  useGetFeatureAccessQuery('enabled')
  const dispatch = useAppDispatch()
  const setOpenForm = useCallback((open: boolean) => dispatch(setOpenItemForm(open)), [dispatch])
  // const dispatch = useAppDispatch();
  const router = useRouter()
  // const { viewMode, checkIfViewMode, linkSettings, endViewMode } = useDemo() // technically share mode is a type of demo mode
  const { } = useSession({
    required: true,
    onUnauthenticated() {
      if (!viewMode.mode) {
        router.push('/about')
      }
      //dispatch(endViewMode())
    },
  })

  const searchParams = useSearchParams()!
  const sharing = searchParams.get('sharing')
  const sharingBy = searchParams.get('sharingby')
  if (sharing && sharingBy && viewMode.mode !== 'share') {
    console.log("sharing set")
    dispatch(setViewMode({ mode: 'share', shareLinkUrlInfo: { sharingId: sharing, sharingBy: sharingBy } }))
  }

  const constraintsRef = useRef(null)
  const { pandaAnimation } = useGlobalPanda()

  useHotkeys('ctrl+q', () => { dispatch(openItemForm()) }) // TODO: use mutate from useSWRConfig for list + day views


  const { data: themeCustomization } = useGetGeneralThemeCustomizationsQuery()

  useLayoutEffect(() => {
    setCustomCategoryColorsGlobally(initialThemeCustomizations)
  }, [initialThemeCustomizations])

  useEffect(() => {
    // TODO: move this to lazy load, and use server components to set this initially
    setCustomCategoryColorsGlobally(themeCustomization)
  }, [themeCustomization])

  return (
    <>
      <LazyLoadHooks />
      <main ref={constraintsRef} className={css({ height: '100vh', display: 'flex', boxSizing: 'border-box' })}>        {/* <Suspense fallback={}> */}
        {/* <Suspense fallback={}> */}
        <Toolbar />
        {/* </Suspense> */}
        <div className={css({ display: 'flex', flex: '1', position: 'relative', minWidth: 0 })}>
          {children}
          <DayPanel ref={constraintsRef} />
          <DayGridPanel ref={constraintsRef} />
          <DemoInfoPanel constraintsRef={constraintsRef} />
          <AnimatePresence initial={true} mode="wait">
            {pandaAnimation && <motion.div className={css({
              position: "fixed",
              zIndex: 1000,
              maxHeight: "25%",
              maxWidth: "clamp(100px, 15%, 300px)",
              bottom: "5vh",
              left: "10vh"
            })} {...animation}>
              <PandaLottie play animationData={pandaAnimation} loop />
            </motion.div>}
          </AnimatePresence>
          <ItemForm open={openForm} setOpen={setOpenForm} defaultValues={defaultValues} />
          <GeneralPanda />
        </div>
      </main>
    </>
  )
}

const DemoContainer = styled(motion.div, {
  base: {
    position: "fixed",
    zIndex: 99999,
    width: 200,
    backgroundColor: "$secondary2",
    bottom: "5vh",
    left: "5vh",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    border: "3px ridge {colors.$gray10}",
    boxShadow: "$strong",
    "&:hover": {
      cursor: "grab"
    }
  }
})

function DemoInfoPanel({ constraintsRef }: {
  constraintsRef: MutableRefObject<any>,
}) {
  const viewMode = useAppSelector((state) => state.viewMode);
  const { data: shareSettings } = useGetShareLinkSettingsQuery(viewMode.shareLinkUrlInfo?.sharingId ?? skipToken)
  const dispatch = useAppDispatch();

  const endView = () => dispatch(endViewMode())

  if (!viewMode.mode) return <></>

  if (viewMode.mode === 'share' && !shareSettings) return (
    <DemoContainer
      drag
      dragConstraints={constraintsRef}
      dragElastic={0.01}
    >
      <Spinner />
      <Link href={'/about'} >
        <Flex onClick={endView} gap={'1'} align={'center'}>End visit <Cross1Icon /></Flex>
      </Link>
    </DemoContainer>
  )

  return <DemoContainer
    drag
    dragConstraints={constraintsRef}
    dragElastic={0.01}
  >

    {shareSettings ? <Header>{shareSettings.ownerName}&apos;s Planda</Header> : <Header>Demo mode: View only</Header>}
    <Link href={'/about'} >
      <Flex onClick={endView} gap={'1'} align={'center'}>End {viewMode.mode === 'demo' ? 'demo' : 'visit'} <Cross1Icon /></Flex>
    </Link>
  </DemoContainer>
}

const Header = styled('h2', {
  base: {
    textAlign: "center"
  }
})

const PandaContainer = styled(motion.div, {
  base: {
    position: "fixed",
    zIndex: 1000,
    maxHeight: "25%",
    maxWidth: "clamp(100px, 15%, 300px)",
    bottom: "5vh",
    left: "10vh"
  }
})

const PandaLottie = styled(Lottie, {
  base: {
    maxWidth: "100%",
    maxHeight: "100%"
  }
})